@import 'styles/app.scss';

.card {
    background: #F7F7F8;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    

    @include mediaGt {
        padding: 20px;
        flex-direction: row;

    }
    @include printer{
        flex-direction: row;
    }
}

.gallery {
    
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

   
    @include mediaGt {
      max-width: 437px;
      max-height: 385px;
      aspect-ratio: 437 / 385;
    }

    @include mediaXx {
      max-width: 528px;
      max-height: 452px;
      aspect-ratio: 528 / 452;
    }

    &_wrapper {
        animation: appear 1s ease forwards; 
        position: relative;
        aspect-ratio: 343 / 343;
       
    @include mediaGt {
        max-width: 437px;
        max-height: 385px;
        aspect-ratio: 437 / 385;
      }

      @include mediaXx {
        max-width: 528px;
        max-height: 452px;
        aspect-ratio: 528 / 452;
      }
    }

    &_slider {
        display: flex;
        height: 100%;
        width: 100%;
        
    }

    &_image {
        object-fit: cover;
        aspect-ratio: 343 / 343;
        width: 100vw;
        max-width: 100vw;
        height: 100%;
        @include mediaGt {
            width: 437px;
            max-width: 437px;
            max-height: 385px;
            aspect-ratio: 437 / 385;
          }
          @include mediaXx {
            width: 528px;
            max-width: 528px;
            max-height: 452px;
            aspect-ratio: 528 / 452;
          }
    }

    .sliderButton {
        display: none;
        position: absolute;
        cursor: pointer;
        z-index: 3;
        top: 45%;
        
        @include mediaGt {
            display: flex;
        }
        @include printer{
            display: none;
        }
      }
      
    .nextButton {
        right: 16px;
        @include mediaGt {
            right: 20px;
        }
    }
      
    .prevButton {
        left: 16px;
        @include mediaGt {
            left: 20px;
        }
    }


    &_top {
        position: absolute;
        z-index: 3;
        top: 20px;
        left: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include mediaMd {
            flex-direction: row;
            align-items: center; 
            
        }

        @include mediaGt {
            left: 20px;
            
        }

        @include mediaXx {
            border-width: 2px;
        }

        &_button {
            padding: 8px 20px;
            color: $colorWhite;
            border: 1.6px solid $colorWhite;
            background: rgba(0, 0, 0, 0.302);
            backdrop-filter: blur(2px);
            border-radius: 30px;
            width: fit-content;
        }
    }

   

      &_video {
        position: absolute;
        z-index: 3;
        bottom: 20px;
        left: 16px;

        @include mediaLg {
            left: 20px;
        }
      }
}

.footer {
    padding: 20px 16px;
    color: $colorAccent;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mediaGt {
      padding: 0 0 0 30px;
    }
    &_top {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
        margin-bottom: 20px;
        h3:first-of-type {
            
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        }

        @include mediaGt {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        .construction {
            color: $colorGray;    
            max-width: 160px;
        }
    }

    &_info {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-bottom: 20px;

        @include mediaGt {
            flex-direction: row;
            gap: 55px;
            margin-bottom: 30px;
        }

        &_item {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    &_block {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &_infoModal {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top: 16px;
        margin-bottom: 32px;

        @include mediaLg {
            border-top: 1px solid $colorDarkWhite;
            padding-top: 16px;
        }

        &_item {
            display: flex;
            align-items: center;
            gap: 5px;
            
        }
    }

    .commentWrapper {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .commentText {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: .25s all;

        &.expanded {
            -webkit-line-clamp: unset;
        }
        
        &--pdf {
            
            -webkit-line-clamp: 4;
        }
    }

   

    .commentButton {
        @include p2;
        text-decoration-line: underline;
    }

    .bottom {
        margin-top: auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        border-top: 1px solid $colorDarkWhite;
    }
}

.pdf {
    height: 426px;
    &__image {
        object-fit: cover;
    }
}
